@import url('https://fonts.googleapis.com/css2?family=Rubik+Distressed%3Aital%2Cwght%400%2C400&amp;display=swap');

:root {
    --control-1: rgb(255,255,255);
    --control-2: rgb(250,250,250);
    --control-3: rgb(235,235,235);
    --control-4: rgb(220,220,220);
    --control-5: rgb(205,205,205);
    --smesshy-green: rgb(112,173,71);
    --smesshy-green-light: rgb(155, 201, 124);
    --smesshy-green-dark: rgb(77,119,49);
    --smesshy-gray: #4D4D4D;
    --smesshy-blue:  #1a5597;
    --smesshy-blue-light: rgb(37, 122, 216);
    --smesshy-blue-dark: rgb(15, 48, 86);
    --smesshy-opaque: rgba(255,255,255, .5);
    --smesshy-yellow: rgb(255, 192, 0);
    --smesshy-orange: rgb(237, 125, 49);
    --smesshy-red: rgb(192, 0, 0);
}

html {
    --smesshy-unit-width: 0.01;
    --smesshy-unit-height: 0.01;
    height: 100%;
    overflow: hidden;
}

a { 
    text-decoration: none; 
}

h1 {
    font-size: calc(var(--smesshy-unit-width) * 32);
}
h2 {
    font-size: calc(var(--smesshy-unit-width) * 24);
}
p {
    font-size: calc(var(--smesshy-unit-width) * 16);
}
li {
    font-size: calc(var(--smesshy-unit-width) * 16);
}


body {
    height: 100%;
    width: 100%;
    min-width: 100%;
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}
 
.app-root {
    height: 100%;
}

.smesshy-gutter {
    height: 100%;
    width: 100%;
    background-color: var(--smesshy-blue);
    touch-action: none;
}

.smesshy-app-host {
    position: absolute;
}

.game-page-base {
    display:flex;
    flex-direction:column;
    align-items:stretch;
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-color: var(--smesshy-green);
    background-color: var(--control-1);
}


.game-page-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    border: 1px solid;
    align-items: center;
    justify-content: space-between;
    padding-right: 4px;
    padding-left: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.game-page-mid {
    min-height: 0px;
    display: flex;
    flex-direction: column;
    flex:auto;
}

.smesshy-main {
    background-color:  var(--smesshy-blue);
}

.smesshy-main-panel {
    background-color: var(--smesshy-green);
    color: white;
}

.game-page-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.icon-button {
    height: 28px;
    padding: 0px;
}

.border-thin {
    border: 1px solid;
}

.text-xlarge {
    font-size: calc(var(--smesshy-unit-width) * 24);
}

.text-large {
    font-size: calc(var(--smesshy-unit-width) * 18);
}
.text-medium {
    font-size: calc(var(--smesshy-unit-width) * 16);
}
.text-small {
    font-size: calc(var(--smesshy-unit-width) * 13);
}
.text-xsmall {
    font-size: x-small;
}

.text-bold {
    font-weight: bold;
}
.text-boldish {
    font-weight: 500;
}

.text-clip {
    overflow: clip;
    white-space: nowrap;
}
.text-line-small {
    line-height: 1.1;
}
.text-line-none {
    line-height: 1;
}

.text-underline {
    text-decoration: underline;
}

.text-vertical-tb {
    writing-mode: vertical-lr;
    text-orientation: mixed;
}
.text-vertical-bt {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
}

.h-items {
    display: flex;
    flex-direction: row;
}

.v-items {
    display: flex;
    flex-direction: column;
}

.h-small-gap {
    gap: calc(var(--smesshy-unit-width) * 2);
}
.v-small-gap {
    gap: calc(var(--smesshy-unit-height) * 2);
}

.h-medium-gap {
    gap: calc(var(--smesshy-unit-width) * 4);
}
.v-medium-gap {
    gap: calc(var(--smesshy-unit-height) * 4);
}
.h-large-gap {
    gap: calc(var(--smesshy-unit-width) * 10);
}
.v-large-gap {
    gap: calc(var(--smesshy-unit-height) * 10);
}

.text-center {
    text-align: center;
}

.text-black {
    color: black;
}
.text-white {
    color: white;

}
.text-green {
    color: var(--smesshy-green);
}
.text-gray {
    color: var(--smesshy-gray);
}


.self-center {
    justify-self: center;
}
.self-right {
    justify-self: right;
}
.self-cross-center {
    align-self: center;
}
.self-cross-end {
    align-self:flex-end;
}
.self-remaining {
    flex-grow: 1;
}

.group-center {
    justify-content: center;
}
.group-left {
    justify-content: left;
}
.group-right {
    justify-content: right;
}

.group-cross-center {
    align-content: center;
}

.each-space-between {
    justify-content: space-between;
}
.each-space-around {
    justify-content: space-around;
}
.each-cross-center {
    align-items: center;
}
.each-center {
    justify-items: center;
}

.align-text-center {
    text-align: center;
}

.h-padding-small {
    padding-left: calc(var(--smesshy-unit-width) * 2);
    padding-right: calc(var(--smesshy-unit-width) * 2);
}
.h-padding-medium {
    padding-left: calc(var(--smesshy-unit-width) * 4);
    padding-right: calc(var(--smesshy-unit-width) * 4);
}
.h-padding-large {
    padding-left: calc(var(--smesshy-unit-width) * 10);
    padding-right: calc(var(--smesshy-unit-width) * 10);
}
.v-padding-small {
    padding-top: calc(var(--smesshy-unit-height) * 2);
    padding-bottom: calc(var(--smesshy-unit-height) * 2);
}
.v-padding-medium {
    padding-top: calc(var(--smesshy-unit-height) * 4);
    padding-bottom: calc(var(--smesshy-unit-height) * 4);
}
.v-padding-large {
    padding-top: calc(var(--smesshy-unit-height) * 10);
    padding-bottom: calc(var(--smesshy-unit-height) * 10);
}

.h-margin-small {
    margin-left: calc(var(--smesshy-unit-width) * 1);
    margin-right: calc(var(--smesshy-unit-width) * 1);
}
.v-margin-small {
    margin-top: calc(var(--smesshy-unit-height) * 1);
    margin-bottom: calc(var(--smesshy-unit-height) * 1);
}
.h-margin-medium {
    margin-left: calc(var(--smesshy-unit-width) * 3);
    margin-right: calc(var(--smesshy-unit-width) * 3);
}
.v-margin-medium {
    margin-top: calc(var(--smesshy-unit-height) * 3);
    margin-bottom: calc(var(--smesshy-unit-height) * 3);
}
.h-margin-large {
    margin-left: calc(var(--smesshy-unit-width) * 6);
    margin-right: calc(var(--smesshy-unit-width) * 6);
}
.v-margin-large {
    margin-top: calc(var(--smesshy-unit-height) * 6);
    margin-bottom: calc(var(--smesshy-unit-height) * 6);
}
.top-margin-large {
    margin-top: calc(var(--smesshy-unit-height) * 6);
}

.height-100p {
    height: 100%
}
.width-100p {
    width: 100%
}
.width-50p {
    width: 50%
}
.width-content {
    width: fit-content;
}
.height-content {
    height: fit-content;
}

.height-auto {
    height: auto;
}



.v-scroll-region {
    display: flex;
    flex-direction:column;
    align-items: stretch;
    min-height: 0px;
}
.v-scroll-container {
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 0px;
}
.h-scroll-region {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-width: 0px;
}
.h-scroll-container {
    overflow-x: scroll;
    overflow-y: hidden;
    min-width: 0px;
}

.flex-auto {
    flex: auto;
}

.transparent {
    background-color: transparent;
    border: none;
}



.resize-handle {
    background-color:white;
    width:calc(var(--smesshy-unit-width) * 10);
    height:calc(var(--smesshy-unit-height) * 10);
    pointer-events:initial;
    border-width:1px;
    border-style:solid;
    border-radius:calc(var(--smesshy-unit-width) * 5);
    touch-action: none;
}

.h-line {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color:#000000;
    opacity: .25;
    width: 100%;
    height: 1px;
}


.smesshy-icon-button {
    border-radius: calc(var(--smesshy-unit-width) * 8);
    border-style: solid;
    border-width: calc(var(--smesshy-unit-width) * 2);
    width: calc(var(--smesshy-unit-width) * 35);
    height: calc(var(--smesshy-unit-height) * 35);
    touch-action: none;
}
.smesshy-icon-button-popped {
    background-color: var(--control-3);
    color:#000000;
    border-top-color: var(--control-2);
    border-right-color: var(--control-2);
    border-left-color: var(--control-5);
    border-bottom-color: var(--control-5);
}

.smesshy-icon-button-pushed {
    background-color: var(--control-1);
    border-top-color: black;
    border-right-color: black;
    border-left-color: black;
    border-bottom-color: black;
}

.smesshy-icon-button-disabled {
    background-color: var(--control-5);
}

.smesshy-icon-button-small {
    border-radius: calc(var(--smesshy-unit-width) * 6);
    width: calc(var(--smesshy-unit-width) * 23);
    height: calc(var(--smesshy-unit-height) * 23);
}

.smesshy-icon-button-medium {
    border-radius: calc(var(--smesshy-unit-width) * 7);
    width: calc(var(--smesshy-unit-width) * 29);
    height: calc(var(--smesshy-unit-height) * 29);
}

.smesshy-vote-button {
    border-radius: calc(var(--smesshy-unit-width) * 6);
    border-style: solid;
    border-width: calc(var(--smesshy-unit-width) * 2);
    width: calc(var(--smesshy-unit-width) * 18);
    height: calc(var(--smesshy-unit-height) * 26);
    touch-action: none;
}
.smesshy-vote-button-pushed {
    background-color: var(--smesshy-green);
    border-color: white;
    
}

.smesshy-main-button {
    border-radius: calc(var(--smesshy-unit-width) * 10);
    min-height: calc(var(--smesshy-unit-height) * 50);
    border-width: calc(var(--smesshy-unit-width) * 4);
    border-style: solid;
    touch-action: none;
    overflow: hidden;
}
.smesshy-main-button-popped {
    padding-left: calc(var(--smesshy-unit-width) * 7);
    padding-right: calc(var(--smesshy-unit-width) * 5);
    padding-top: 0px;
    padding-bottom: calc(var(--smesshy-unit-height) * 1);
    border-top-color: var(--smesshy-green-light);
    border-right-color: var(--smesshy-green-light);
    border-bottom-color: var(--smesshy-green-dark);
    border-left-color: var(--smesshy-green-dark);
}
.smesshy-main-button-pushed {
    padding-top: calc(var(--smesshy-unit-height) * 1);
    padding-bottom: 0px;
    padding-left: calc(var(--smesshy-unit-width) * 5);
    padding-right: calc(var(--smesshy-unit-width) * 7);
    border-top-color: var(--smesshy-green-dark);
    border-right-color: var(--smesshy-green-dark);
    border-bottom-color: var(--smesshy-green-light);
    border-left-color: var(--smesshy-green-light);
}

.smesshy-sub-button {
    border-radius: calc(var(--smesshy-unit-width) * 4);
    background-color: var(--smesshy-green);
    border-width: calc(var(--smesshy-unit-width) * 2);
    border-style: solid;
    min-height: calc(var(--smesshy-unit-height) * 30);
    width: fit-content;
    touch-action: none;
}

.smesshy-sub-button-popped {
    padding-left: calc(var(--smesshy-unit-width) * 7);
    padding-right: calc(var(--smesshy-unit-width) * 5);
    padding-top: 0px;
    padding-bottom: calc(var(--smesshy-unit-height) * 1);
    border-top-color: var(--smesshy-green-light);
    border-right-color: var(--smesshy-green-light);
    border-bottom-color: var(--smesshy-green-dark);
    border-left-color: var(--smesshy-green-dark);
}

.smesshy-sub-button-pushed {
    padding-top: calc(var(--smesshy-unit-height) * 1);
    padding-bottom: 0px;
    padding-left: calc(var(--smesshy-unit-width) * 5);
    padding-right: calc(var(--smesshy-unit-width) * 7);
    border-top-color: var(--smesshy-green-dark);
    border-right-color: var(--smesshy-green-dark);
    border-bottom-color: var(--smesshy-green-light);
    border-left-color: var(--smesshy-green-light);
}

.smesshy-sub-button-disabled {
    border-top-color: var(--control-3);
    border-right-color: var(--control-3);
    border-bottom-color: var(--control-4);
    border-left-color: var(--control-4);
    background-color: var(--control-5);
}

.smesshy-wtf {
    position: relative;
    width: calc(var(--smesshy-unit-width) * 24);
    height: calc(var(--smesshy-unit-height) * 30);
    top: calc(var(--smesshy-unit-height) * -31);
    left:calc(var(--smesshy-unit-width) * 324);
}

.smesshy-paint-button {
    background-image: url("paint.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.smesshy-touch-pan-y {
    touch-action:pan-y;
}

.smesshy-grey-out {
    background-color: rgb(0,0,0,.5);
    position: absolute;
    top: 0px;
    left: 0px;
    height:100%;
    width:100%;
}

.smesshy-ask {
    border-radius: calc(var(--smesshy-unit-width) * 6);
    background-color:  var(--smesshy-blue);
    margin-left: calc(var(--smesshy-unit-width) * 10);
    margin-right: calc(var(--smesshy-unit-width) * 10);
    border-style: solid;
    border-top-color: gray;
    border-right-color: gray;
    border-left-color: var(--smesshy-gray);
    border-bottom-color: var(--smesshy-gray);
    border-width: thin;
    box-shadow: calc(var(--smesshy-unit-width) * -10) calc(var(--smesshy-unit-height) * 10) calc(var(--smesshy-unit-width) * 15) #4d4d4d;
}

.smesshy-ask-inset {
    border-top-color: var(--smesshy-blue-dark);
    border-right-color: var(--smesshy-blue-dark);
    border-bottom-color: var(--smesshy-blue-light);
    border-left-color: var(--smesshy-blue-light);
    border-width: calc(var(--smesshy-unit-width) * 2);
    border-style: solid;
    border-radius: calc(var(--smesshy-unit-width) * 4);
    margin-top: calc(var(--smesshy-unit-height) * 6);
    margin-bottom: calc(var(--smesshy-unit-height) * 6);
    padding: calc(var(--smesshy-unit-width) * 2);
}

.smesshy-heading-outset {
    border-bottom-color: var(--smesshy-blue-dark);
    border-left-color: var(--smesshy-blue-dark);
    border-top-color: var(--smesshy-blue-light);
    border-right-color: var(--smesshy-blue-light);
    border-width: calc(var(--smesshy-unit-width) * 2);
    border-style: solid;
    border-radius: calc(var(--smesshy-unit-width) * 4);
    padding: calc(var(--smesshy-unit-width) * 2);
    margin-left: calc(var(--smesshy-unit-height) * 10);
    margin-right: calc(var(--smesshy-unit-height) * 10);
    height: calc(var(--smesshy-unit-height) * 34);
    margin-top: calc(var(--smesshy-unit-height) * -18);
    background-color: var(--smesshy-blue);
}


.smesshy-group {
    border-color: var(--smesshy-green);
    border-width: calc(var(--smesshy-unit-width) * 2);
    border-style: solid;
    border-radius: calc(var(--smesshy-unit-width) * 4);
    padding: calc(var(--smesshy-unit-width) * 4);
}

.smesshy-icon-bar {
    background-color: var(--smesshy-green);
    color: white;
    font-size: larger;

}
.smesshy-sub-palette {
    width: calc(var(--smesshy-unit-width) * 160);
    height: calc(var(--smesshy-unit-height) * 160);
}
.smesshy-slider {
    width: calc(var(--smesshy-unit-width) * 160);
    height: calc(var(--smesshy-unit-height) * 26);
    background: #eee;
    opacity: 0.8;
    outline: none;
    -webkit-appearance: none;
}

.smesshy-color-volume-host {
    width: calc(var(--smesshy-unit-width) * 160);
    height: calc(var(--smesshy-unit-height) * 8);
}
  
.smesshy-slider:hover {
    opacity: 1;
}
  
.smesshy-slider::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: calc(var(--smesshy-unit-width) * 30);
    height: calc(var(--smesshy-unit-height) * 24);
    background: #4d4d4d;
    cursor: pointer;
}

.smesshy-input {
    background: var(--smesshy-green-light);
    color: black;
}


.settings-calibrate-box {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 120px auto auto;
    background-color:white;
    height:100%;
    width:100%;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 2px;
}
.settings-calibrate-object {
    background-color:var(--smesshy-green);
    color: white;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 8px;
    padding: 10px;
    width: fit-content;
    height: fit-content;
    margin-left:20px;
    margin-right:20px;
 
}
.settings-calibrate-target {
    background-color:var(--smesshy-gray);
    color: white;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 8px;
    padding: 20px;
    width: fit-content;
    height: fit-content;
    margin:20px;
}

.mini-lunchbox {
    width:calc(var(--smesshy-unit-width) * 36);
    height:calc(var(--smesshy-unit-height) * 36);
    padding-left:calc(var(--smesshy-unit-width) * 4);
}

.artist-tile-grid {
    display: grid;
    grid-template-rows: calc(var(--smesshy-unit-height) * 29) calc(var(--smesshy-unit-height) * 18) calc(var(--smesshy-unit-height) * 18);
    grid-template-columns: max-content fit-content(calc(var(--smesshy-unit-width) * 36)) auto minmax(0, 1fr) fit-content(calc(var(--smesshy-unit-width) * 36)) fit-content(calc(var(--smesshy-unit-width) * 36));
}

.gallery-doors-grid {
    display: grid;
    grid-template-rows: calc(var(--smesshy-unit-height) * 31) calc(var(--smesshy-unit-height) * 197) calc(var(--smesshy-unit-height) * 31) calc(var(--smesshy-unit-height) * 197) calc(var(--smesshy-unit-height) * 31) calc(var(--smesshy-unit-height) * 197);
}

.sub-pallette-grid {
    display: grid;
    grid-template-rows: 1fr calc(var(--smesshy-unit-height) * 168) calc(var(--smesshy-unit-height) * 168);
}

div {
    box-sizing:border-box;
    user-select:none;
}

.react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: calc(var(--smesshy-unit-width) * 50);
    height: calc(var(--smesshy-unit-height) * 24);
    padding: 0;
    border-radius: calc(var(--smesshy-unit-width) * 30);
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: var(--smesshy-green);
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--smesshy-green);
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: calc(var(--smesshy-unit-width) * 14);
    height: calc(var(--smesshy-unit-height) * 10);
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: calc(var(--smesshy-unit-width) * 8);
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: calc(var(--smesshy-unit-width) * 10);
    height: calc(var(--smesshy-unit-height) * 10);
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: calc(var(--smesshy-unit-width) * 10);
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: calc(var(--smesshy-unit-height) * 1);
    left: calc(var(--smesshy-unit-width) * 1);
    width: calc(var(--smesshy-unit-width) * 22);
    height: calc(var(--smesshy-unit-height) * 22);
    border: calc(var(--smesshy-unit-width) * 1) solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: calc(var(--smesshy-unit-width) * 27);
    border-color: var(--smesshy-green);
  }
  
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px calc(var(--smesshy-unit-width) * 2) calc(var(--smesshy-unit-width) * 3) #0099E0;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px calc(var(--smesshy-unit-width) * 5) calc(var(--smesshy-unit-width) * 5) #0099E0;
  }
